import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Container from 'theme/atoms/Container'
import Link from 'theme/atoms/Link'
import MagazineArticleWidget from 'theme/molecules/MagazineArticleWidget'
import Meta from 'theme/atoms/Meta'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import {BannerProvider} from 'hooks/useBanner'

type Author = {
  urlKey: string
  firstname: string
  teaserPictureUrl: string
  base64: string
}

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    article: {
      story: any
      title: string
      breadcrumbTitle: string
      headline?: string
      teaser: string
      metaDescription: string
      metaRobotsNoindex: boolean
      canonicalUrl: string
      author: string
      authors: Author[]

      relatedMagazineArticles: {
        urlKey: string
        categoryName: string
        teaserImageUrl: string
        title: string
        teaser: string
        sponsoredArticle: boolean
        base64: string
      }[]
    }
    banner: {
      title:string
      objectID:string
      popupCtaLabel:string
      popupCtaUrl:string
      popupDescription:string
      popupHintText:string
      popupTitle:string
      subtitle:string
      backgroundColor: string
    } | null
  }
}

export default (props: Props) => {
  const { article } = props.data
  const { story } = article

  const crumbs = [
    { label: 'Startseite', link: '/' },
    { label: 'Magazin', link: '/magazin/' },
    { label: article.breadcrumbTitle || article.title },
  ]

  return (
    <BannerProvider banner={props.data.banner}>
      <Layout small breadcrumbs={crumbs} partialStateUpdates={story.partialStateUpdates}>
        <Meta
          rss
          type="article"
          pathname={props.location.pathname}
          title={article.title}
          description={article.metaDescription}
          canonicalUrl={article.canonicalUrl}
          noFollow={article.metaRobotsNoindex}
        />

        <Teaser small>
          <h1>{article.headline || article.title}</h1>
          {Boolean(article.authors.length) && (
            // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
            <div className="autors">
              Ein Artikel von&nbsp;{' '}
              {article.authors.map((author, i) => (
                <AuthorComp key={author.urlKey} {...{ author, i }} />
              ))}
            </div>
          )}
          {Boolean(article.authors.length) || (
            // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
            <div className="autors">Ein Artikel von {article.author}</div>
          )}
          <h3>{article.teaser}</h3>
        </Teaser>

        <Story small key={props.location.key} story={story} />

        <MagazineArticleWidget.Collection
          title="Lesenswert"
          articles={article.relatedMagazineArticles}
        />
      </Layout>
    </BannerProvider>
  )
}

function AuthorComp({ author, i }: { author: Author; i: number }) {
  const [ref, img] = useLazyImageSrc(author.teaserPictureUrl, author.base64, 100)
  return (
    <React.Fragment>
      {i > 0 && (
        // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
        <span>&nbsp;und&nbsp;</span>
      )}
      <Link to={'/author/' + author.urlKey}>
        {author.firstname}&nbsp; <img ref={ref} src={img} alt={author.urlKey} />
      </Link>
    </React.Fragment>
  )
}

const Teaser = styled(Container)`
  > h1 {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 3px solid #262626;
    color: #262626;
    font-family: 'Cormorant Garamond';
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 48px;
  }

  > h3 {
    text-align: center;
  }

  > .autors,
  .autors > a {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
    }
  }
`

export const query = graphql`
  query($urlKey: String!) {
    article: magazineArticle(urlKey: { eq: $urlKey }) {
      story
      title
      headline
      metaDescription
      teaser
      canonicalUrl
      metaRobotsNoindex
      breadcrumbTitle
      author
      authors {
        urlKey
        firstname
        teaserPictureUrl
        base64(fieldName: "teaserPictureUrl")
      }
      relatedMagazineArticles {
        urlKey
        categoryName
        teaserImageUrl
        title
        teaser
        categoryName
        sponsoredArticle
        base64(fieldName: "teaserImageUrl")
      }
    }
    banner(displayOnContentPages: {eq: true}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
    }
  }
`
