import * as React from 'react'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import styled from 'styled-components'
import Container from 'theme/atoms/Container'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'

type Article = {
  urlKey: string
  teaserImageUrl: string
  title: string
  categoryName: string
  sponsoredArticle: boolean
  teaser: string
  base64: string
}

type Props = {
  article: Article
}

function MagazineArticleWidget({ article }: Props) {
  const [ref, image] = useLazyImageSrc(article.teaserImageUrl, article.base64)
  return (
    <Wrapper
      data-cy-ctx="molecules/MagazineArticleWidget"
      ref={ref}
      to={'/magazin/a/' + article.urlKey}
      className="MagazineArticleWidget full-width-MOBILE"
    >
      <div className="image-wrapper">
        <img src={image} alt={article.title} />
        <div className="shaddow-spacer"></div>
      </div>
      <div className="content">
        <h2 className="category">
          {article.categoryName}
          {article.sponsoredArticle && <span data-cy-state="sponsored"> (Anzeige)</span>}
        </h2>
        <h5 className="title">{article.title}</h5>
        <div className="teaser">{article.teaser}</div>
      </div>
    </Wrapper>
  )
}

type CollectionProps = {
  articles: Article[]
  title: string
}

function Collection({ articles, title }: CollectionProps) {
  return (
    <CollectionWrapper className="MagazineArticleWidget-Collection">
      <h2 className="title">{title}</h2>
      <div className="list">
        {articles.map((art) => (
          <MagazineArticleWidget key={art.urlKey} article={art} />
        ))}
      </div>
    </CollectionWrapper>
  )
}

MagazineArticleWidget.Collection = Collection

export default MagazineArticleWidget

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: #555;
  position: relative;

  > .image-wrapper {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;

    > img {
      width: 500px;
      height: 500px;
      position: absolute;
    }

    > .shaddow-spacer {
      height: 15em;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  > .content {
    position: absolute;
    bottom: 0px;
    padding: 10px;

    > .category {
      padding: 3px;
      background: #993452;
      color: white;
      display: inline;
    }
    > .title {
      font-size: 22px;
      color: white;
    }
    > .teaser {
      color: white;
    }
  }

  @media (min-width: ${ms.TABLET}px) {
    display: flex;
    align-items: center;
    color: #555;

    > .image-wrapper {
      width: 240px;
      height: 240px;
      margin-right: 10px;
      > img {
        width: 100%;
        height: 100%;
        position: initial;
      }
      > .shaddow-spacer {
        display: none;
      }
    }

    > .content {
      position: initial;
      flex: 1;
      margin-top: -20px;

      > .category {
        padding: 3px;
        background: #993452;
        color: white;
        display: inline;
      }
      > .title {
        font-size: 22px;
        color: #555;
      }
      > .teaser {
        color: #555;
      }
    }
  }
`

const CollectionWrapper = styled(Container)`
  @media (min-width: ${ms.TABLET}px) {
    .MagazineArticleWidget {
      margin-bottom: 15px;
    }
  }

  > .title {
    border-bottom: 2px solid black;
  }
`
